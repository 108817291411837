@use 'sass:math';
@import-normalize;

$card-aspect-ratio: math.div(169.075, 244.64);

html {
  box-sizing: border-box;
  background-color: rgb(22, 85, 34);
  background-image: url(./felt.jpg);
  background-repeat: repeat;
  height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
  font-family: roboto;
}

html, body {
  padding: 0;
  margin: 0;
}

body {
  min-height: 100%;
}

h2, h3, ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.game {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column-reverse;
  justify-content: space-between;
}


.owns-crib {
  color: gold;
}

$current-turn-color: rgb(221, 130, 44);

.game .player {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .owns-crib {
    color: gold;
    position: absolute;
    font-size: 24px;
    top: -15px;
    right: -15px;
    transform: rotate(45deg);
  }

  &.has-action .cards {
    box-shadow: 0px -20px 40px 20px $current-turn-color;
  }

  header {
    position: relative;
    margin: 0 0 -1px 0;
    padding: 3px 6px 0;
    align-self: center;
    background-color:rgb(240, 240, 240);

    border: 1px solid rgb(175, 175, 175);
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1;

    .user-name {
      position: relative;
    }

    .user-name span,
    .user-name input {
      font-size: 1em;
      font-weight: bold;
      margin: 0;
      padding: 0;
      border: none;
      background: none;
    }
    .user-name span {
      color: transparent;
      background-color: transparent;
      visibility: hidden;
    }
    .user-name input {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .action {
    align-self: stretch;
    height: 68px;
    height: calc(68px + env(safe-area-inset-bottom, 0));
    background-color:rgb(240, 240, 240);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 10px 0;
    padding-bottom: calc(10px + env(safe-area-inset-bottom, 0));
  }

  .action button {
    background-color:rgb(250, 250, 250);
    border: 1px solid rgb(175, 175, 175);
    height: 44px;
    min-width: 100px;
    max-width: 300px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    color: #000;

    cursor: pointer;

    &:focus, &:hover {
      background-color: rgb(51, 170, 51);
      color: #fff;
    }
    &:active {
      transform: scale(0.9);
    }
  }

  .action button .card-horizontal {
    padding: 10px 0;
    margin: 0 .2em;
    margin-left: .2em;
    border: none;
    min-width: 0;
    min-height: 0;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .cards {
    flex: 1 1 auto;
    align-self: stretch;
    margin: 0;
    border-top: 1px solid rgb(175, 175, 175);
    padding: 6px 6px 0;
    padding: 6px
      calc(6px + env(safe-area-inset-right, 0))
      0
      calc(6px + env(safe-area-inset-left, 0));
    background-color:rgb(240, 240, 240);

    li {
      display: inline-block;
      margin: 0;
      padding: 0;

      max-width: 100px;
    }

    label {
      cursor: pointer;
    }

    label, .card {
      display: inline-block;
      width: 100%;
    }

    label input[type=radio] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    label input[type=radio]:checked + .card-small {
      outline: none;
      padding: 4px 7px;
      border: 2px solid rgb(51, 170, 51);
      background-color: rgba(51, 170, 51, 0.1);
    }

    label input[type=radio]:checked + .card-small + .card-large {
      padding: 0;
      border: 7px solid rgb(51, 170, 51);
      background-color:  rgb(51, 170, 51);
      border-radius: 12px;
    }

    label input[type=radio]:disabled + .card-small,
    label input[type=radio]:disabled + .card-small + .card-large {
      cursor: default;
    }

    .card-large {
      padding: 7px;
      display: none;
    }
    @media (min-width:768px) {
      .card-small {
        display: none;
      }
      .card-large {
        display: inline-block;
      }
    }
  }

  &.disabled .cards {
    label input[type=radio]:disabled + .card-small,
    label input[type=radio]:disabled + .card-small + .card-large {
      opacity: .75;
    }
  }
}

$hand-width: 4rem;
$card-width: 50px;
$large-card-width: 100px;
$hand-background: rgba(255, 255, 255, .2);

@media (min-width:768px) {
  .game .hand.player .action button {
    height: 56px;
  }
}

.game .common {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-around;

  position: relative;
  padding: ($hand-width + 1rem) ($hand-width + 1rem) 0;
  padding: calc(#{$hand-width + 1rem} + env(safe-area-inset-top, 0))
    calc(#{$hand-width + 1rem} + env(safe-area-inset-right, 0))
    env(safe-area-inset-bottom, 0)
    calc(#{$hand-width + 1rem} + env(safe-area-inset-left, 0));
  min-height: 300px;
}

.pre-play, .play-wrapper {
  background-color: rgba(255, 255, 255, .1);
  box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.1);
  border-radius: 20px;
  padding: 5px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;

  h2 {
    color: #fff;
  }
}

.play-wrapper {
  flex-direction: column;
  align-items: stretch;
  @media (min-width:768px) {
    flex-direction: row;  
  }
}

.deck {
  display: flex;
  flex-direction: column;

  .card-small {
    display: inline-block;
    width: 44px;
  }

  .card-large {
    display: none;
  }
  .card-back {
    display: block;
    width: 44px;
  }
  @media (min-width:768px) {
    .card-small {
      display: none;
    }
    .card-large {
      display: block;
      width: 75px;
    }
  }

  button {
    margin-top: 10px;
    border: 1px solid #000;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    padding: 3px 6px;
    color: #000;
  }
  button:focus, button:hover {
    background-color: rgb(51, 170, 51);
    color: #fff;
    &:disabled {
      color: #000;
      background-color: #fff;
    }
  }
  button:active {
    transform: scale(0.9);
  }
  button:disabled {
    opacity: .5;
    cursor: default;
    transform: none;
  }
  .has-action {
    box-shadow: 0px 0px 10px 7px $current-turn-color;
  }
}

.users {
  flex-direction: column;
  z-index: -1;
}

.pre-play > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.crib {
  max-width: 100px;
  li {
    display: inline-block;
    width: 40px;
  }

  .full {
    display: none;
  }
  @media (min-width:450px) {
    max-width: none;
  }
  @media (min-width:768px) {
    max-width: none;
    .mini {
      display: none;
    }
    .full {
      display: block;
      li {
        width: 75px
      }
    }
  }

  .crib-owner {
    color: #fff;
    font-size: .9rem;
  }
}

.users ol {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.users ol > li {
  display: block;
  position: absolute;
  border-radius: 25px;

  .cards-wrapper {
    position: absolute;
    padding: 0.5rem;
  }
  .cards {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;

    > li {
      position: relative;
      flex: 1 1 auto;
      &:last-of-type {
        flex: 1 0 auto;
      }
      > .card {
        position: absolute;
      }
    }
  }

  .owns-crib {
    display: inline-block;
    width: 0;
    position: relative;
    left: 5px;
  }
  h3 {
    display: inline;
  }

  $name-wrapper-width: 8rem;
  &:nth-child(1),
  &:nth-child(3) {
    box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.5);
    background-color: rgba(255, 255, 255, .6);
    &.has-action {
      box-shadow: 0px 0px 10px 2px $current-turn-color;
      background-color: transparentize($color: $current-turn-color, $amount: 0.2);
    }
    width: $hand-width;
    top: 70%;
    transform: translateY(-70%);
    min-height: 150px;
    height: 50%;
    max-height: 75%;

    right: 0;
    left: auto;

    .name-wrapper {
      position: absolute;
      top: 50%;
      width: $name-wrapper-width;
      transform: translateY(-50%);
    }

    .cards-wrapper {
      top: 0;
      width: 200%;
      height: 100%;
    }
    .cards {
      flex-direction: column;
    }
    .cards > li {
      &, .card {
        height: $card-width;
        @media (min-width:768px) and (min-height: 700px) {
          height: $large-card-width;
        }
      }
      width: 100%;
    }
  }
  &:nth-child(1) {
    left: 0;
    width: calc(#{$hand-width} + env(safe-area-inset-left, 0));
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    .name-wrapper {
      right: #{math.div(-$name-wrapper-width, 2rem + 0.5rem)}rem;
    }
    header {
      transform: rotate(90deg);
    }
    .cards-wrapper {
      right: 1rem;
    }
    .cards > li .card {
      right: 0;
    }
  }
  &:nth-child(3) {
    right: 0;
    width: calc(#{$hand-width} + env(safe-area-inset-right, 0));
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    .name-wrapper {
      left: #{math.div(-$name-wrapper-width, 2rem + 0.5rem)}rem;
    }
    header {
      transform: rotate(270deg);
    }
    .cards-wrapper {
      left: 1rem;
    }
    .cards {
      flex-direction: column-reverse;
      > li {
        &:last-of-type {
          flex: 1 1 auto;
        }
        &:first-of-type {
          flex: 1 0 auto;
        }
        .card {
          left: 0;
        }
      }
    }
  }

  &:nth-child(2),
  &:nth-child(4) {
    background: none;
    border-radius: 0;
    width: 100%;
    left: 0;
    height: $hand-width;

    .hand-wrapper {
      border-radius: 25px;
      box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.5);
      background-color: rgba(255, 255, 255, .6);
      width: 75%;
      max-width: 400px;
      margin: 0 auto;

      display: flex;
    }
    &.has-action .hand-wrapper {
      box-shadow: 0px 0px 10px 2px $current-turn-color;
      background-color: transparentize($color: $current-turn-color, $amount: 0.2);
    }

    .cards-wrapper {
      left: 0;
      width: 100%;
      height: 200%;
    }
    .cards {
      flex-direction: row;
    }
    .cards > li {
      &, .card {
        width: $card-width;
        @media (min-width:350px) {
          width: $large-card-width;
        }
      }
      height: 100%;
    }
  }

  &:nth-child(2) {
    top: 0;
    height: calc(#{$hand-width} + env(safe-area-inset-top, 0));
    .hand-wrapper {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      flex-direction: column-reverse;
    }
    .cards-wrapper {
      height: 200%;
      bottom: 1rem;
    }

    .cards > li { 
      overflow: hidden;
      .card {
        top: calc(100% - 2rem);
      }
    }
  }
  &:nth-child(4) {
    bottom: 0;
    height: calc(#{$hand-width} + env(safe-area-inset-bottom, 0));
    .hand-wrapper {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      flex-direction: column;
    }
    .cards-wrapper {
      top: 1rem;
    }
    .cards > li .card {
      top: 0;
    }
  }

  .hand-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  header {
    text-align: center;
  }

  .deal {
    position: absolute;
    top: 100%;
  }
}

.card-small {
  font-weight: bold;
  width: 1.2em;
  width: calc(1em + 16px + 4px);
  padding: 5px 8px;
  margin: 0 2px;
  border: 1px dotted #000;
  border-radius: 5px;
  background-color: #fff;
}

.card-small.card-horizontal {
  padding: 1px;
  margin: 0;
}

.card-back {
  fill: #cc3333;
  border: none;
}

section.play {
  position: relative;
  text-align: center;
  flex: 1 1 auto;
  min-width: 50px; // Stop this from causing an overflow

  .cards {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
  }
  li {
    flex: 0 0 auto;
    width: 20px;
    .card {
      width: 45px;
    }
    margin-left: 3px;
  }
  .full {
    display: none;
  }
  @media (min-width:768px) {
    .mini {
      display: none;
    }
    .full {
      display: flex;
      li {
        .card {
          width: 75px;
        }
        width: 25px;
      }
    }
  }

  .play-total {
    position: absolute;
    top: 5px;
    left: 5px;
    color: #fff;
    font-size: .8em;
    font-weight: 300;
  }

  li + .last-card {
    margin-left: 10px + 20px;
    @media (min-width:768px) {
      margin-left: 20px + 50px;
    }
  }
  .last-card, .last-card ~ li {
    svg {
      background-color: #fff;
      border-radius: 5px;
      > text, > use {
        opacity: .6;
      }
    }
  }

  .last-card {
    margin-left: 10px;
    @media (min-width:768px) {
      margin-left: 20px;
    }
    position: relative;

    &::before {
      position: absolute;
      display: block;
      height: 90%;
      top: 5%;
      left: -5px;
      @media (min-width:768px) {
        left: -10px;
      }
      content: " ";
      border-left: 1px solid #000;
    }
  }
}

section.scores {
  flex: 0 1 auto;
  position: relative;
  color: #fff;

  @media (min-width:768px) {
    padding-left: 20px;
    &::before {
      position: absolute;
      display: block;
      height: 90%;
      top: 5%;
      left: 10px;
      content: " ";
      border-left: 1px solid #000;
    }
  }

  dd {
    margin-bottom: 6px;
  }
}

.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.not-a-player.game {
  .hand.player {
    display: none;
  }

  .users ol > li {
    &:nth-child(1),
    &:nth-child(3) {
      top: 50%;
      transform: translateY(-50%);
      min-height: 150px;
      height: 50%;
      max-height: 60%;
    }
  }

  .common {
    padding-bottom: ($hand-width + 1rem);
  }
  p {
    color: #fff;
    text-align: center;
    margin: 0;
    font-weight: 300;
    font-style: italic;
  }
}

.space-holder {
  visibility: hidden;
}

button.options {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  box-shadow: none;
  color: #ffffff99;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  z-index: 100;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.ReactModal__Content {
  overflow: auto;

  ol li {
    margin: 5px 0;
  }
  ol button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    font-size: 1rem;
    padding: 0 2px;
  }
  .watcher {
    margin-top: 2rem;
  }
  .watcher + .watcher {
    margin-top: 0;
  }

  .checkbox-setting, .select-setting {
    display: block;
    &:first-of-type, &:nth-of-type(2), &:nth-of-type(3) {
      margin-top: 2rem;
    }
  }
}

.debug {
  display: none;
}
